import { RefObject, useRef } from 'react'

import { Show } from '~/components'
import { usePagination } from '~/hooks/usePagination/usePagination'
import { InfoCircleOutlined } from '~/icons'
import { useStore } from '~/store/store'
import { highlightNeedlesDebounced, needleToTokens } from '~/utils/highlightNeedlesDebounced'

import { useColumnsToShow } from '../hooks/useColumnsToShow'
import { useWaitingListResultsMessage } from '../hooks/useWaitingListResultsMessage'
import { selectSortedWaitingListItems } from '../selectors/waitingListItems'
import { useKeyboardNavigation } from './KeyboardNavigation'
import { LoadingRows } from './LoadingRows'
import { NoResultsTableRow } from './NoResultsTableRow'
import { Pagination } from './Pagination/Pagination'
import { TableHeaderRow } from './TableHeaderRow'
import { TableRow } from './TableRow'

type Props = {
    isLoading: boolean
    containerRef: RefObject<HTMLDivElement>
}

export function Table({ isLoading, containerRef }: Props) {
    const selectedPage = useStore(state => state.waitingList.selectedPage)
    const { setSelectedPage } = useStore(state => state.waitingList.actions)
    const itemsPerPage = useStore(state => state.waitingList.itemsPerPage)
    const pageRawNeedle = useStore(state => state.waitingList.pageRawNeedle)
    const needle = pageRawNeedle.toLowerCase()
    const tableRef = useRef<HTMLTableSectionElement>(null)
    const headerRef = useRef<HTMLTableSectionElement>(null)
    const paginationRef = useRef<HTMLDivElement>(null)

    highlightNeedlesDebounced(needleToTokens(needle), tableRef)

    const tableItems = useStore(selectSortedWaitingListItems) // filtered, transformed and sorted items

    const { pageItems, ...paginationProps } = usePagination(tableItems, selectedPage, itemsPerPage)
    useKeyboardNavigation(pageItems, containerRef, paginationRef, headerRef)

    const columnsToShow = useColumnsToShow()

    const isNoResultsFound = !isLoading && pageItems.length === 0

    const { tableMessage, tooltipMessage } = useWaitingListResultsMessage(isNoResultsFound, needle)

    return (
        <div className="flex h-full w-full flex-col gap-2">
            <div className="grow overflow-scroll border">
                <table className="min-w-full" data-test="waiting-list-loaded">
                    <thead className="border-b" ref={headerRef}>
                        <TableHeaderRow />
                    </thead>
                    <tbody ref={tableRef} className={`w-full text-left text-sm font-normal text-gray-700`}>
                        <Show condition={!isLoading} fallback={<LoadingRows columnsToShow={Object.keys(columnsToShow).length} rowsToShow={itemsPerPage} />}>
                            {pageItems.map((item, index) => (
                                <TableRow key={item.BookingId} item={item} className="border-b last:border-0" data-test={`waiting-list-item-${index}`} />
                            ))}
                        </Show>
                        <Show condition={isNoResultsFound}>
                            <NoResultsTableRow columnsToShow={Object.keys(columnsToShow).length} message={tableMessage} />
                        </Show>
                    </tbody>
                </table>
            </div>
            <div ref={paginationRef} className="flex w-full shrink items-center justify-center gap-1 bg-white">
                <Pagination setSelectedPage={setSelectedPage} {...paginationProps} />
                {tooltipMessage && <InfoCircleOutlined data-tooltip={tooltipMessage} />}
            </div>
        </div>
    )
}
