import { motion } from 'framer-motion'
import { createRef } from 'react'

import PageLayout from '~/components/PageLayout'
import { useImportSurgeryTypeGroupEntities } from '~/hooks/useImportSurgeryTypeGroupEntities'
import { useStore } from '~/store/store'

import { WaitingListSidebar } from './Drawer/WaitingListSidebar'
import { Header } from './Header/Header'
import { useImportEntities } from './hooks/useImportEntities'
import { Table } from './Table/Table'

const WaitingListPage = () => {
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)
    const drawerProps = useStore(state => state.waitingList.drawerProps)

    // Enable mapping from surgery type to surgery category in operation type filter
    useImportSurgeryTypeGroupEntities()
    const ref = createRef<HTMLDivElement>()
    const { isLoading } = useImportEntities(activeView)

    return (
        <PageLayout
            content={<Table isLoading={isLoading} containerRef={ref} />}
            header={<Header />}
            sidebar={
                <motion.div
                    initial={false /** Reduces motion when switching between pages. */}
                    animate={{ width: drawerProps ? '30rem' : 0 }}
                    transition={{ duration: 0.2 }}
                    className="h-full"
                >
                    <WaitingListSidebar />
                </motion.div>
            }
        />
    )
}

export default WaitingListPage
