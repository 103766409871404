import { PractitionerScheduleStatus } from '~/store/selectors'
import { Status, statusesMap } from '~/store/slices/filterSlice'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'
import { createGetStatusStateFn, useToggleStatuses } from '../hooks'
import { DatePractitionerCell } from '../utils'

type Props = {
    selectedCells: DatePractitionerCell[]
    scheduleStatuses: PractitionerScheduleStatus[]
    status: Status
    autoFocus?: boolean
}

export const StatusTag = ({ selectedCells, scheduleStatuses, status, autoFocus = false }: Props) => {
    const getStatusState = createGetStatusStateFn(selectedCells, scheduleStatuses)
    const { toggleStatus, isLoading } = useToggleStatuses(selectedCells, scheduleStatuses)

    function getTooltip(status: Status) {
        if (selectedCells.length !== 1) return statusesMap[status].tooltip

        const scheduleStatus = scheduleStatuses.find(scheduleStatus => scheduleStatus.status_code === status)
        if (!scheduleStatus) return statusesMap[status].tooltip

        const editInfo = `Sist endret av ${scheduleStatus.updated_by} ${humanizeDate(day(scheduleStatus.updated_at))}`
        return `${statusesMap[status].tooltip}\n${editInfo}`
    }

    return (
        <ButtonTag
            autoFocus={autoFocus}
            isLoading={isLoading}
            colorType={statusesMap[status].color}
            fillType={getStatusState(status)}
            onClick={() => toggleStatus(status)}
            data-tooltip={getTooltip(status)}
            className="transition-all"
        >
            {statusesMap[status].label}
        </ButtonTag>
    )
}
