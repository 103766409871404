import { PillsSelect } from '~/components'
import { Option, selectClearedValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

const clearedOptions: Option[] = [
    { label: 'Klarert', value: 'Klarert' },
    { label: 'Ikke klarert', value: 'Ikke klarert' },
]

export const ClearedFilter = () => {
    const clearedValues = useStore(selectClearedValues)
    const toggleFilteredBy = useStore(state => state.appFilters.actions.toggleFilteredBy)
    const setSelectedPage = useStore(state => state.waitingList.actions.setSelectedPage)

    const handleOnChange = (value: string, label: string) => {
        toggleFilteredBy({ type: 'cleared', value, label })
        setSelectedPage(1)
    }

    return <PillsSelect values={clearedValues} options={clearedOptions} onChange={({ value, label }) => handleOnChange(value, label)} />
}
