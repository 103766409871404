import clsx from 'clsx'
import { Fragment, memo, ReactNode, RefObject } from 'react'

import { Id } from '../utils'

export type FooterProps<Col extends { id: Id }> = {
    key: string
    rowHeader: () => ReactNode
    cellClassName?: (header: Col) => string
    cellRender: (header: Col) => ReactNode
}

export type FootersProps<Col extends { id: Id }> = {
    cols: Col[]
    gridTemplateColumns: string
    footerRef?: RefObject<HTMLDivElement>
    footers?: FooterProps<Col>[]
}

function FootersComponent<Header extends { id: Id }>({ footers, cols, gridTemplateColumns, footerRef }: FootersProps<Header>) {
    return (
        <div
            ref={footerRef}
            className="sticky -bottom-px z-10 grid whitespace-nowrap border-t border-t-[#8391c3] bg-white"
            style={{
                gridTemplateColumns,
                gridColumn: `1 / span ${cols.length + 1}`,
            }}
        >
            {footers?.map(footer => (
                <Fragment key={footer.key}>
                    <div className="sticky left-0 truncate border-r border-t border-r-[#8391c3] bg-white p-1">{footer.rowHeader()}</div>

                    {cols.map(header => (
                        <div key={header.id} className={clsx('border-r border-t bg-white p-1 last:border-r-0', footer.cellClassName?.(header))}>
                            {footer.cellRender(header)}
                        </div>
                    ))}
                </Fragment>
            ))}
        </div>
    )
}

export const Footers = memo(FootersComponent) as typeof FootersComponent
