import clsx from 'clsx'
import { memo } from 'react'

import { PractitionerSchedule } from '~/store/selectors'
import { selectStatusValues, Status, statusesMap } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { day, humanizeDate } from '~/utils/extendedDayjs'

import { ButtonTag } from '../../shared/ButtonTag'

function isStringStatus(status: string): status is Status {
    return status in statusesMap
}

type Props = {
    schedule: PractitionerSchedule | null
}

const CellScheduleStatusesComponent = ({ schedule }: Props) => {
    const filteredStatuses = useStore(selectStatusValues)
    const gap = Number(schedule?.statuses.length) >= 4 ? '-space-x-4' : '-space-x-4 2xl:gap-x-0.5 2xl:space-x-0'

    return (
        <div className={clsx('flex items-stretch gap-x-0', gap)}>
            {schedule?.statuses.map(({ status_code: status, updated_by, updated_at }, index) => {
                if (!isStringStatus(status)) return null

                const editInfo = `Sist endret av ${updated_by} ${humanizeDate(day(updated_at))}`
                const tooltip = `${statusesMap[status].tooltip}\n${editInfo}`

                return (
                    <ButtonTag
                        key={status}
                        data-tooltip={tooltip}
                        tabIndex={-1}
                        colorType={statusesMap[status].color}
                        fillType="all"
                        className={clsx('!min-w-[20px] truncate border border-white', {
                            'opacity-30': filteredStatuses.length > 0 && !filteredStatuses.includes(status),
                        })}
                        style={{
                            zIndex: schedule.statuses.length - index,
                        }}
                    >
                        {statusesMap[status].label}
                    </ButtonTag>
                )
            })}
        </div>
    )
}

export const CellScheduleStatuses = memo(CellScheduleStatusesComponent)
