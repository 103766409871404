import { AnimatePresence, motion } from 'framer-motion'
import { useEffect, useMemo, useState } from 'react'

import { DipsSchemas, getBaseHeaders, getClient } from '~/clients/dips-client'
import { HeroDialog } from '~/components'
import env from '~/env'
import { ExclamationCircleOutlined } from '~/icons'
import { OccupancyData, UnScheduledSurgery } from '~/store/selectors'
import { getDayOvernight } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { getPatientAgeGroup } from '~/store/utils/patientHelpers'
import { formatPersonalId, getAssistants, getSurgeons, SurgeryResourceNames } from '~/utils/dips'
import { day, format, getFormattedDuration } from '~/utils/extendedDayjs'
import { formatDateToNorskReadableIdDate } from '~/utils/utils'

import { zeroDurationToken } from '../SurgeryCard'
import { BookingForm } from './BookingForm'
import { ProcessBooking } from './ProcessBooking'

type ScheduleSurgeryRequest = DipsSchemas['ScheduleSurgeryRequest']

const testingHeaders = {
    'x-mock-should-fail': 'false',
    'x-mock-should-have-delay': 'false',
}

const getEnvHeaders = () => {
    if (env.VITE_REMOVE_BOOKING_TEST_FLAGS) return testingHeaders
    return {}
}

type Props = { waitingListItem: UnScheduledSurgery; occupancyData: OccupancyData; isOpen: boolean; onCloseDialog: () => void }

export const BookingDialog = ({ waitingListItem, isOpen, onCloseDialog, occupancyData }: Props) => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isSuccess, setIsSuccess] = useState<boolean>(false)
    const [bookingRequestError, setBookingRequestError] = useState<string | undefined>()
    const [checkInTime, setCheckInTime] = useState<string>('')
    const [surgerySagaIdToPoll, setSurgerySagaIdToPoll] = useState<string | undefined>()

    const newResourceSlots = useMemo(
        () =>
            waitingListItem.surgeryResources?.map(res => {
                // can only map the new resource if it is a theater room, assignment of surgeons coming in the future
                const newResourceId = res.typeId === SurgeryResourceNames.TheaterRoom ? occupancyData.location.dipsResource?.id : res.resourceId

                return { resourceId: newResourceId, id: res.id }
            }),
        [occupancyData.location.dipsResource?.id, waitingListItem.surgeryResources]
    )

    useEffect(() => {
        if (!bookingRequestError) return
        const id = setTimeout(() => {
            setBookingRequestError(undefined)
        }, 3000)

        return () => {
            clearTimeout(id)
        }
    }, [bookingRequestError])

    const handleBookingRequestClick = async (newCheckInTime: string, startTime: string) => {
        if (!waitingListItem.bookingId || !waitingListItem.contact?.version || !waitingListItem.treatmentLocationId || !waitingListItem.version) {
            return
        }
        const newSagaId = crypto.randomUUID()

        const scheduleRequest: ScheduleSurgeryRequest = {
            scheduleSurgeryParams: [
                {
                    sagaId: newSagaId,
                    bookingId: waitingListItem.bookingId,
                    checkInTime: occupancyData.date
                        .set('hour', Number(newCheckInTime.split(':')[0]))
                        .set('minute', Number(newCheckInTime.split(':')[1]))
                        .toISOString(),
                    plannedTheatreInTime: occupancyData.date
                        .set('hour', Number(startTime.split(':')[0]))
                        .set('minute', Number(startTime.split(':')[1]))
                        .toISOString(),
                    contactVersion: waitingListItem.contact.version,
                    // these resources are incorrect until we have a way to get the correct ones from PF-1565
                    surgeryResources: newResourceSlots ?? [],
                    treatmentLocationId: waitingListItem.treatmentLocationId,
                    version: waitingListItem.version,
                },
            ],
        }

        try {
            setIsLoading(true)
            const res = await getClient().POST('/api/v1/surgery/schedule', {
                body: scheduleRequest,
                headers: {
                    ...getBaseHeaders(),
                    ...getEnvHeaders(),
                },
            })
            const statusInfo = res.response
            if (statusInfo.status !== 200) {
                throw new Error('Hero fikk ikke kontakt med DIPS. Prøv å bestille operasjonen på nytt.')
            }
            setIsSuccess(true)
            setCheckInTime(newCheckInTime)
            setSurgerySagaIdToPoll(newSagaId)
        } catch (e) {
            console.error((e as Error).message)
            setBookingRequestError((e as Error).message)
        } finally {
            setIsLoading(false)
        }
    }

    const operatingRoom = occupancyData.location.dipsResource

    const ageGroups = useStore(state => state.di.entities.ageGroups)

    const patientAgeGroup = waitingListItem.patient && ageGroups ? getPatientAgeGroup(waitingListItem.patient, Object.values(ageGroups.byId)) : undefined

    const displayPatientName = `${waitingListItem.patient?.firstName} ${waitingListItem.patient?.lastName} (${formatPersonalId(waitingListItem.patient?.nationalId ?? '')})`

    const displayDate = formatDateToNorskReadableIdDate(occupancyData.date).label
    // ------ Tags ---------
    const asa = waitingListItem.surgeryOrderDetails?.asa

    const nprCodeName = waitingListItem.contact?.levelOfCareNpr?.nprCodeName

    const dayOvernight = getDayOvernight(nprCodeName)

    // ----------------------

    const practitioners = useMemo(
        () => [...getSurgeons(waitingListItem.surgeryResources), ...getAssistants(waitingListItem.surgeryResources)],
        [waitingListItem.surgeryResources]
    )

    const handleClose = () => {
        setIsSuccess(false)
        setIsLoading(false)
        onCloseDialog()
    }

    return (
        <HeroDialog
            isOpen={isOpen}
            onCloseDialog={handleClose}
            title={isSuccess ? 'Bestilling av operasjon til denne pasienten er gjennomført' : 'Bekreft bestilling av operasjonstid'}
        >
            <div className="relative grid gap-4">
                <AnimatePresence>
                    {bookingRequestError && (
                        <motion.div
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ type: 'linear' }}
                            data-test="booking-request-error"
                            className="absolute top-0 flex w-fit items-center gap-0.5 justify-self-end rounded-sm bg-red-500 py-0.5 pl-0.5 pr-1 align-middle text-xs text-white"
                        >
                            <ExclamationCircleOutlined height={20} />
                            {bookingRequestError}
                        </motion.div>
                    )}
                </AnimatePresence>
                {isSuccess && surgerySagaIdToPoll ? (
                    <ProcessBooking
                        sagaId={surgerySagaIdToPoll}
                        bookingId={waitingListItem.bookingId}
                        operation={waitingListItem.surgeryType?.name ?? ''}
                        patient={displayPatientName}
                        displayDate={displayDate}
                        location={operatingRoom?.shortName ?? ''}
                        practitioners={practitioners}
                        onCloseDialog={handleClose}
                        checkInTime={checkInTime}
                    />
                ) : (
                    <BookingForm
                        displayDate={displayDate}
                        bookingId={waitingListItem.bookingId}
                        defaultKnifeTime={getFormattedDuration(waitingListItem.plannedProcedureDuration ?? zeroDurationToken, true)}
                        defaultCheckInTime={format(day(waitingListItem.contact?.checkInTime), 'HH:mm')}
                        comment={waitingListItem.contact?.coordinationComment ?? ''}
                        bookingDate={occupancyData.date}
                        location={operatingRoom?.shortName ?? ''}
                        practitioners={practitioners}
                        patient={displayPatientName}
                        patientAgeGroup={patientAgeGroup}
                        isShortNotice={waitingListItem.contact?.isShortNotice}
                        operation={waitingListItem.surgeryType?.name ?? ''}
                        asa={asa}
                        dayOvernight={dayOvernight}
                        onClose={handleClose}
                        onSubmit={handleBookingRequestClick}
                        isLoading={isLoading}
                    />
                )}
            </div>

            {env.VITE_REMOVE_BOOKING_TEST_FLAGS && <div data-test="test-flags-disabled" />}
        </HeroDialog>
    )
}
