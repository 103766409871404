import { PagePaths } from '~/app/routes'

import { useImportFutureScheduledEntities } from './useImportFutureScheduledEntities'
import { useImportUnScheduledEntities } from './useImportUnScheduledEntities'

function useImportBoth() {
    const { isLoading: isUnscheduledLoading } = useImportUnScheduledEntities()
    const { isLoading: isScheduledLoading } = useImportFutureScheduledEntities()

    return { isLoading: isUnscheduledLoading && isScheduledLoading } // The && is intentional. It allows for displaying partial data
}

export function useImportEntities(view: PagePaths['WAITING_LIST']): { isLoading: boolean } {
    return {
        '/waiting-list/unscheduled': useImportUnScheduledEntities(),
        '/waiting-list/scheduled': useImportFutureScheduledEntities(),
        '/waiting-list/all': useImportBoth(),
    }[view]
}
