import clsx from 'clsx'
import { DetailedHTMLProps, HTMLAttributes, ReactNode, useEffect, useMemo, useRef, useState } from 'react'

import { useOnClickOutside } from '~/components'

import { Show } from '../Show'
import { Body, ExternalBodyProps, ExternalHeaderProps, FooterProps, Footers, Header, Loader, Modal, ModalProps } from './components'
import { Selections, useCreateKeyboardNavigation, useDataGridKeys, useRecalculatePositionOnNavigation } from './hooks'
import { Cell, Id, toCellId, toCells } from './utils'

type DataGridDivProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, 'style'>
type KeyboardInteractionComponentProps = (
    isModalOpen: boolean,
    setIsModalOpen: (isOpen: boolean) => void,
    selectedCells: Cell[],
    clearSelection: () => void
) => ReactNode

type Props<Row extends { id: Id }, Col extends { id: Id }> = DataGridDivProps & {
    header: ExternalHeaderProps<Col>
    body: ExternalBodyProps<Row, Col>
    footers?: FooterProps<Col>[]
    modal?: ModalProps
    keyboardInteractionComponent?: KeyboardInteractionComponentProps

    isLoading?: boolean
    firstColumnWidth?: number
    selection?: Selections
}

export function DataGrid<Row extends { id: Id }, Col extends { id: Id }>({
    header,
    body,
    footers,
    keyboardInteractionComponent,
    className,
    firstColumnWidth = 130,
    selection,
    modal,
    isLoading,
    ...props
}: Props<Row, Col>) {
    const gridTemplateColumns = `repeat(${header.cells.length + 1}, minmax(24px, 1fr))`
    const cols = header.cells
    const rows = body.rows

    const [isModalOpen, setIsModalOpen] = useState(false)
    const { cursor, selectedCells, setDefault, onKeyDown, onMouseDown } = useCreateKeyboardNavigation({ rows, cols, selection })

    const dataGridRef = useRef<HTMLDivElement>(null)
    const headerRef = useRef<HTMLDivElement>(null)
    const footerRef = useRef<HTMLDivElement>(null)

    useRecalculatePositionOnNavigation({ dataGridRef, cursor, firstColumnWidth, headerRef, footerRef })
    useDataGridKeys({ dataGridRef, setIsModalOpen, selectedCells, isModalOpen, setDefault })
    useOnClickOutside(dataGridRef, setDefault)

    const allCells = useMemo(() => toCells(selectedCells), [selectedCells])
    const cursorCellEl = document.getElementById(toCellId({ rowIndex: cursor.row, colIndex: cursor.col }))

    // Dangerous one (might be performance threat)
    // but this way on each re-render we ensure that focus was received by the cursor cell
    // Btw, so far after testing I haven't noticed any performance issues, but it's worth to keep an eye on it
    useEffect(() => {
        if (isModalOpen) return
        if (!cursorCellEl) return

        cursorCellEl.focus()
    })

    return (
        <Show condition={!isLoading} fallback={<Loader />}>
            <div
                ref={dataGridRef}
                className={clsx('grid max-h-full max-w-full select-none overflow-auto rounded border border-[#8391c3]', className, {
                    'overflow-hidden': isModalOpen,
                })}
                style={{ gridTemplateColumns }}
                {...props}
            >
                <Header headerRef={headerRef} gridTemplateColumns={gridTemplateColumns} {...header} />
                <Body
                    cols={cols}
                    {...body}
                    setIsModalOpen={setIsModalOpen}
                    cursor={cursor}
                    selectedCells={selectedCells}
                    onMouseDown={onMouseDown}
                    onKeyDown={onKeyDown}
                />
                <Footers cols={cols} footers={footers} footerRef={footerRef} gridTemplateColumns={gridTemplateColumns} />
                {modal && <Modal cursor={cursor} allCells={allCells} isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} modal={modal} />}
                {keyboardInteractionComponent && keyboardInteractionComponent(isModalOpen, setIsModalOpen, allCells, setDefault)}
            </div>
        </Show>
    )
}
