import { flip, offset } from '@floating-ui/react'
import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { FC, useState } from 'react'

import { Popover, Show } from '~/components'
import { CheckOutlined, CommentOutlined } from '~/icons'
import { Location, selectGetBlockLocks, selectGetLocationSchedules } from '~/store/selectors'
import { useStore } from '~/store/store'
import { isNotNullish } from '~/utils/guards'

type Props = {
    date: Dayjs
    location: Location
    reduceOpacity?: boolean
}

export const BlockIcons: FC<Props> = ({ date, location, reduceOpacity = false }) => {
    const [hoverBlockLock, setHoverBlockLock] = useState(false)
    const [hoverComment, setHoverComment] = useState(false)

    const locationSchedule = useStore(selectGetLocationSchedules).byDateAndLocation(date, location)
    const blockLock = useStore(selectGetBlockLocks).byDateAndLocationId(date, location.id)

    const comment = locationSchedule?.comment
    const isComment = comment !== undefined && comment !== ''
    const showIcons = isNotNullish(blockLock) || isComment

    return (
        <Show condition={showIcons}>
            <div className={clsx('flex gap-1', { 'opacity-50': reduceOpacity })}>
                <Show condition={isComment}>
                    <Popover
                        className="flex"
                        middleware={[offset(5), flip()]}
                        open={hoverComment}
                        onOpenChange={setHoverComment}
                        trigger={
                            <div onMouseEnter={() => setHoverComment(true)} onMouseLeave={() => setHoverComment(false)}>
                                <CommentOutlined className="text-blue-700" />
                            </div>
                        }
                    >
                        <div className="max-w-[280px]">
                            <p className="border-b p-2 font-medium">Kommentar</p>
                            <p className="break-words p-2">{comment}</p>
                        </div>
                    </Popover>
                </Show>
                <Show condition={isNotNullish(blockLock)}>
                    <Popover
                        className="flex"
                        middleware={[offset(5), flip()]}
                        open={hoverBlockLock}
                        onOpenChange={setHoverBlockLock}
                        trigger={
                            <div onMouseEnter={() => setHoverBlockLock(true)} onMouseLeave={() => setHoverBlockLock(false)}>
                                <CheckOutlined className="text-blue-700" />
                            </div>
                        }
                    >
                        <div className="max-w-[280px]">
                            <p className="border-b p-2 font-medium">Fullt</p>
                            <p className="p-2">Stuen er markert som full denne dagen.</p>
                        </div>
                    </Popover>
                </Show>
            </div>
        </Show>
    )
}
