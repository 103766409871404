import { InfoCircleOutlined } from '~/icons'

export const NotShownInDIPSWarning = () => (
    <div className="my-4 bg-yellow-100 px-4 pb-4 pt-2">
        <p className="flex items-center gap-2 py-2 font-semibold">
            <InfoCircleOutlined />
            Den nye kommentaren vil ikke vises i DIPS
        </p>
        <p>Kommentaren du skriver her vil ikke oppdateres i DIPS. Du finner den opprinnelige oppmøtekommentaren fra DIPS under.</p>
    </div>
)

export const NotShownInDIPSHint = () => (
    <p className="flex items-center gap-2 py-2">
        <InfoCircleOutlined />
        Denne kommentaren vises ikke i DIPS
    </p>
)
