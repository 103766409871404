import { AnimatePresence } from 'framer-motion'
import { useState } from 'react'

import { RadioButtons } from '~/components'
import { useStore } from '~/store/store'

import { BookingDrawerAvailability } from './BookingDrawerAvailability'
import { BookingDrawerBookings } from './BookingDrawerBookings'
import { BookingDrawerHeader } from './BookingDrawerHeader'
import { Comment } from './Comment'

type FilterTypes = 'availability' | 'bookings' | 'all'
type FilterOption = { label: string; value: FilterTypes }
const listOptions: FilterOption[] = [
    { label: 'Alle', value: 'all' },
    { label: 'Bestilte Operasjoner', value: 'bookings' },
    { label: 'Ledige tider', value: 'availability' },
]
export const BookingDrawer = () => {
    const { set } = useStore(state => state.operationalPlanner.actions)
    const { showAvailability } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const [listFilter, setListFilter] = useState<FilterTypes>('all')

    const onClose = () => {
        set({
            drawerProps: null,
            isDrawerOpen: false,
        })
    }

    return (
        <div className="h-full w-[30rem] overflow-y-auto overflow-x-hidden" data-test="BookingDrawer">
            <div className="sticky top-0 z-10 bg-white">
                <BookingDrawerHeader onClose={onClose} />
                <Comment />
            </div>
            <div className="flex flex-col gap-3 px-4 py-3">
                <RadioButtons options={listOptions} onChange={selected => setListFilter(selected)} value={listFilter} />
                <AnimatePresence initial={false}>
                    {(listFilter === 'all' || listFilter === 'bookings') && <BookingDrawerBookings key="booked" />}
                    {(listFilter === 'all' || listFilter === 'availability') && showAvailability && <BookingDrawerAvailability key="available" />}
                </AnimatePresence>
            </div>
        </div>
    )
}
