import { memo, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

const typeToStylesMap = {
    operation: {
        none: 'text-heroBlack border-heroOperation',
        some: 'text-heroBlack border-heroOperation from-heroOperation from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroOperation bg-heroOperation',
    },
    day_surgery: {
        none: 'text-heroBlack border-heroDaySurgery',
        some: 'text-heroBlack border-heroDaySurgery from-heroDaySurgery from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroDaySurgery bg-heroDaySurgery',
    },
    polyclinic: {
        none: 'text-heroBlack border-heroPolyclinic',
        some: 'text-heroBlack border-heroPolyclinic from-heroPolyclinic from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroPolyclinic bg-heroPolyclinic',
    },
    eveningPolyclinic: {
        none: 'text-heroBlack border-heroEveningPolyclinic',
        some: 'text-heroBlack border-heroEveningPolyclinic from-heroEveningPolyclinic from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroEveningPolyclinic bg-heroEveningPolyclinic',
    },
    onCall: {
        none: 'text-heroBlack border-heroOnCall',
        some: 'text-heroBlack border-heroOnCall from-heroOnCall from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroOnCall bg-heroOnCall',
    },
    vacation: {
        none: 'text-heroBlack border-heroVacation',
        some: 'text-heroBlack border-heroVacation from-heroVacation from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroVacation bg-heroVacation',
    },
    other: {
        none: 'text-heroBlack border-heroOther',
        some: 'text-heroBlack border-heroOther bg-heroOther from-heroOther from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroOther bg-heroOther',
    },
    room: {
        none: 'text-heroBlack border-heroRoom',
        some: 'text-heroBlack border-heroRoom bg-heroRoom from-heroRoom from-50% to-white to-55% bg-gradient-to-br',
        all: 'text-heroBlack border-heroRoom bg-heroRoom',
    },
    red: {
        none: 'text-red-300 border-red-300',
        some: 'text-red-300 border-red-400 bg-red-100',
        all: 'text-white border-red-300 bg-red-300',
    },
} as const
type ColorType = keyof typeof typeToStylesMap

type ButtonProps = JSX.IntrinsicElements['button']

type TagProps = {
    colorType: ColorType
    fillType: 'none' | 'some' | 'all'
    isLoading?: boolean
    children?: ReactNode
}

const baseStyles = 'w-full min-w-[64px] cursor-pointer rounded border py-0.5 font-medium'

export const ButtonTag = memo(({ colorType, fillType, className, children, isLoading = false, ...buttonProps }: TagProps & ButtonProps) => {
    const style = typeToStylesMap[colorType][fillType]

    return (
        <button
            className={twMerge(
                `${style} ${baseStyles} disabled:pointer-events-none disabled:opacity-50 ${isLoading ? 'animate-pulse' : ''} ${!!buttonProps.onClick ? 'active:scale-95' : ''}`,
                className
            )}
            {...buttonProps}
        >
            {children}
        </button>
    )
})
