import clsx from 'clsx'
import { Fragment, memo, ReactNode } from 'react'

import { Cursor, KeyboardNavigationListeners, SelectedCells } from '../hooks'
import { Id, toCellId } from '../utils'
import { CellRender, TableCell } from './Cell'

type BodyProps<Row extends { id: Id }, Col extends { id: Id }> = KeyboardNavigationListeners & {
    cursor: Cursor
    selectedCells: SelectedCells
    setIsModalOpen: (isOpen: boolean) => void
    cols: Col[]
    rows: Row[]
    rowRender: (row: Row) => ReactNode
    rowClassName?: (row: Row) => string
    cellRender: CellRender<Row, Col>
    cellClassName?: (row: Row, col: Col) => string
}

export type ExternalBodyProps<Row extends { id: Id }, Col extends { id: Id }> = Omit<
    BodyProps<Row, Col>,
    'cols' | 'cursor' | 'selectedCells' | 'onMouseDown' | 'onKeyDown' | 'setIsModalOpen'
>

function BodyComponent<Row extends { id: Id }, Col extends { id: Id }>({
    cursor,
    selectedCells,
    setIsModalOpen,
    rows,
    cols,
    rowRender,
    cellRender,
    rowClassName,
    cellClassName,
    onMouseDown,
    onKeyDown,
}: BodyProps<Row, Col>) {
    return (
        <>
            {rows.map((row, rowIndex) => (
                <Fragment key={row.id}>
                    <div
                        className={clsx('sticky left-0 z-10 flex items-center truncate border-b border-r border-r-[#8391c3] bg-white p-1', rowClassName?.(row))}
                    >
                        {rowRender(row)}
                    </div>

                    {cols.map((col, colIndex) => {
                        const isCursor = cursor.row === rowIndex && cursor.col === colIndex

                        return (
                            <div
                                autoFocus={isCursor}
                                id={toCellId({ rowIndex, colIndex })}
                                tabIndex={isCursor ? 0 : -1}
                                key={`${row.id}-${col.id}`}
                                aria-rowindex={rowIndex}
                                aria-colindex={colIndex}
                                data-row-id={row.id}
                                data-col-id={col.id}
                                className={clsx(
                                    'min-w-[28px] cursor-pointer rounded-none border-b border-r p-1 outline-none last:border-r-0',
                                    cellClassName?.(row, col),
                                    {
                                        '!bg-diBlue-400/20': selectedCells.has(toCellId({ rowIndex, colIndex })),
                                        'fake-border': cursor.row === rowIndex && cursor.col === colIndex,
                                    }
                                )}
                                onMouseDown={e => {
                                    if (isCursor) {
                                        setIsModalOpen(true)
                                        return
                                    }

                                    onMouseDown(e, rowIndex, colIndex)
                                }}
                                onKeyDown={onKeyDown}
                            >
                                <TableCell cellRender={cellRender} col={col} row={row} />
                            </div>
                        )
                    })}
                </Fragment>
            ))}
        </>
    )
}

export const Body = memo(BodyComponent) as typeof BodyComponent
