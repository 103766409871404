import { useState } from 'react'

import { ConfirmFn } from '~/components'
import { deleteBatchPractitionerScheduleLocation, deleteBatchPractitionerScheduleStatus } from '~/store/di-entity.api'
import { PractitionerScheduleLocation, PractitionerScheduleStatus } from '~/store/selectors'

export const useDeleteStatusesAndLocations = (
    scheduleStatuses: PractitionerScheduleStatus[],
    scheduleLocations: PractitionerScheduleLocation[],
    confirm: ConfirmFn
) => {
    const [isLoading, setIsLoading] = useState(false)

    function shouldBeConfirmed() {
        return scheduleStatuses.length > 2 || scheduleLocations.length > 2
    }

    function createStatusesAndLocationsString() {
        let string = ''

        if (scheduleStatuses.length > 0) {
            string += `${scheduleStatuses.length} statuser`
        }

        if (scheduleLocations.length > 0) {
            if (scheduleStatuses.length > 0) {
                string += ' og '
            }

            string += `${scheduleLocations.length} lokasjoner`
        }

        return `Slette ${string}?`
    }

    async function deleteStatusesAndLocations() {
        if (shouldBeConfirmed()) {
            const isConfirmed = await confirm({
                title: createStatusesAndLocationsString(),
                description: 'Aktivitetene og lokasjonene blir borte for alltid.',
                cancelButtonLabel: 'Avbryt',
                confirmButtonLabel: 'Slett',
            })
            if (!isConfirmed) return
        }

        setIsLoading(true)
        try {
            await Promise.all([
                deleteBatchPractitionerScheduleStatus(scheduleStatuses.map(({ id }) => id)),
                deleteBatchPractitionerScheduleLocation(scheduleLocations.map(({ id }) => id)),
            ])
        } catch (error) {
            console.error(error)
            return
        } finally {
            setIsLoading(false)
        }
    }

    return { isLoading, deleteStatusesAndLocations }
}
