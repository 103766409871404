import { motion } from 'framer-motion'

import PageLayout from '~/components/PageLayout'
import { useStore } from '~/store/store'

import { AvailableTime } from './AvailableTime/AvailableTime'
import { BookingDrawer } from './BookingDrawer/BookingDrawer'
import { Calendar } from './Calendar/Calendar'
import { Header } from './Header/Header'

const Content = () => {
    const isCalendarView = useStore(state => state.app.activeViews.OPERATIONAL_PLANNER) === '/operational-planner/calendar'
    return isCalendarView ? <Calendar /> : <AvailableTime />
}

const OperationalPlannerPage = () => {
    const isSidebarOpen = useStore(state => state.operationalPlanner.isDrawerOpen)

    return (
        <div className="flex h-full flex-col">
            <PageLayout
                header={<Header />}
                content={<Content />}
                sidebar={
                    <motion.div
                        initial={false /** Reduces motion when switching between pages. */}
                        animate={{ width: isSidebarOpen ? '30rem' : 0 }}
                        transition={{ duration: 0.3 }}
                        className="h-full"
                    >
                        {isSidebarOpen ? <BookingDrawer /> : null}
                    </motion.div>
                }
            />
        </div>
    )
}

export default OperationalPlannerPage
