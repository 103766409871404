import { HeroDialog, TextInput } from '~/components'

type Props = { isOpen: boolean; onClose: () => void }

export const SurgerySearchDialog = ({ isOpen, onClose }: Props) => {
    return (
        <HeroDialog isOpen={isOpen} onCloseDialog={onClose} title="Finn pasient til denne tiden">
            <div className="flex w-96 flex-col gap-2">
                <label className="flex flex-col gap-2">
                    Søk etter navn eller personnummer
                    <TextInput type="search" aria-label="Søk etter navn eller personnummer" placeholder="Søk etter navn eller personnummer" />
                </label>
            </div>
        </HeroDialog>
    )
}
