import { useState } from 'react'

import { RadioButtons } from '~/components'
import env from '~/env'
import { CloseOutlined } from '~/icons'
import { useStore } from '~/store/store'

import { BookingContent } from './Booking/BookingContent'
import { Contents } from './Contents'
import { WaitingListDrawerHeader } from './Header'

type FilterTypes = 'book' | 'patientInfo'
type FilterOption = { label: string; value: FilterTypes }

const listOptions: FilterOption[] = [
    { label: 'Om Inngrepet', value: 'book' },
    { label: 'Pasientinformasjon', value: 'patientInfo' },
]
export const WaitingListSidebar = () => {
    const { setDrawerProps } = useStore(state => state.waitingList.actions)

    const [listFilter, setListFilter] = useState<FilterTypes>(env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER ? 'book' : 'patientInfo')

    function onClose() {
        setDrawerProps(null)
    }

    return (
        <div className="flex h-full w-[30rem] flex-col" data-test="Waitinglist Sidebar">
            <div className="flex border-b px-3 py-2.5">
                <div className="grow">
                    <WaitingListDrawerHeader />
                </div>
                <div onClick={onClose} className="cursor-pointer rounded-full hover:bg-slate-300">
                    <CloseOutlined />
                </div>
            </div>

            <div className="flex flex-col gap-4 overflow-auto px-4 py-4">
                {env.VITE_FF_OPERATIONAL_PLANNER_WAITINGLIST_IN_DRAWER && (
                    <RadioButtons options={listOptions} onChange={selected => setListFilter(selected)} value={listFilter} />
                )}
                {listFilter === 'book' && <BookingContent />}
                {listFilter === 'patientInfo' && <Contents />}
            </div>
        </div>
    )
}
