import dayjs from 'dayjs'

import { selectGetLocations, selectGetScheduledSurgeries } from '~/store/selectors'
import { useStore } from '~/store/store'

import { EmptyItem } from './EmptyItem'
import { SurgeryCard } from './SurgeryCard'

export const BookingDrawerBookings = () => {
    const { location_id, unixDate } = useStore(state => state.operationalPlanner.drawerProps) ?? {}
    const getScheduledSurgeries = useStore(selectGetScheduledSurgeries)
    const getLocations = useStore(selectGetLocations)
    const location = location_id !== undefined ? getLocations.byId(location_id) : null
    const date = dayjs.unix(unixDate ?? 0)

    if (!unixDate) return null
    if (!location) return null

    const bookedSurgeries = getScheduledSurgeries.byDateAndRoomCode(date, location.room_code)

    return (
        <div>
            <div className="flex items-center gap-4 pb-1">
                <p className="text-base font-semibold">Bestilte Operasjoner</p>
                {bookedSurgeries.length > 0 && <p className="text-sm text-gray-500">{bookedSurgeries.length} bestilt</p>}
            </div>
            <div className="flex flex-col gap-2">
                {bookedSurgeries.length > 0 ? (
                    bookedSurgeries.map((surgery, index) => <SurgeryCard surgery={{ type: 'scheduled', value: surgery }} key={index} />)
                ) : (
                    <EmptyItem>
                        <p>Det er ingen pasienter på programmet.</p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-loaded" className="hidden" />
            </div>
        </div>
    )
}
