import { Show } from '~/components'
import { HandshakeOutlined, PendingCircleOutlined, PersonAlertOutlined, PersonCancelOutlined, PersonCheckedOutlined } from '~/icons'

const statusColors = {
    notCompleted: { backgroundColor: 'bg-neutral-100', lineColor: 'bg-neutral-300' },
    completed: { backgroundColor: 'bg-green-100', lineColor: 'bg-green-300' },
    warning: { backgroundColor: 'bg-orange-100', lineColor: 'bg-orange-300' },
}

const iconStyles = 'h-4 w-4 shrink-0'

const statuses = {
    cleared: {
        notCompleted: { icon: <PersonCancelOutlined className={`${iconStyles} fill-stone-400`} />, tooltip: 'Ikke klarert' },
        completed: { icon: <PersonCheckedOutlined className={`${iconStyles} fill-black`} />, tooltip: 'Klarert' },
        warning: { icon: <PersonAlertOutlined className={`${iconStyles} fill-orange-500`} />, tooltip: 'Ikke klarert. Trenger oppfølging.' },
    },
    confirmed: {
        notCompleted: { icon: <PendingCircleOutlined className={`${iconStyles} fill-stone-400`} />, tooltip: 'Pasient ikke bekreftet oppmøte' },
        completed: { icon: <HandshakeOutlined className={`${iconStyles} fill-black`} />, tooltip: 'Pasient bekreftet oppmøte' },
    },
}

type StatusType = keyof typeof statuses

type Status = {
    [S in StatusType]: {
        type: S
        value: keyof (typeof statuses)[S]
    }
}[StatusType]

type StatusProps = {
    status: Status
    showLine?: boolean
}

export function Status({ status, showLine = false }: StatusProps) {
    const { type, value } = status

    let result
    if (type === 'cleared') {
        result = statuses[type][value]
    } else {
        result = statuses[type][value]
    }

    const { icon, tooltip } = result
    const { backgroundColor, lineColor } = statusColors[value]

    return (
        <div className="flex shrink-0 items-center" data-tooltip={tooltip}>
            <div className={`flex h-6 w-6 items-center justify-center rounded-full p-1 ${backgroundColor}`}>{icon}</div>
            {showLine && <div className={`h-[1px] w-[2px] ${lineColor}`} />}
        </div>
    )
}

export const getCompletedStatus = (completed: boolean) => (completed ? 'completed' : 'notCompleted')

export type FormattedStatus = 'klarert' | 'bekreftet' // Norwegian to be searchable in the table

type StatusesProps = {
    statuses: FormattedStatus[]
    isScheduledItem: boolean
}

export const Statuses = ({ statuses, isScheduledItem }: StatusesProps) => {
    const isCleared = statuses.includes('klarert')
    const isConfirmed = statuses.includes('bekreftet')

    const clearedStatus = isScheduledItem && !isCleared ? 'warning' : getCompletedStatus(isCleared)
    const confirmedStatus = getCompletedStatus(isConfirmed)

    return (
        <div className="flex flex-row">
            <Status status={{ type: 'cleared', value: clearedStatus }} showLine={isScheduledItem} />
            <Show condition={isScheduledItem}>
                <Status status={{ type: 'confirmed', value: confirmedStatus }} />
            </Show>
        </div>
    )
}
