import dayjs from 'dayjs'
import { Duration } from 'dayjs/plugin/duration'

import { DipsSchemas } from '~/clients/dips-client'

export type MinimalScheduledSurgery = DipsSchemas['MinimalScheduledSurgeryResult']

function getDayjsDuration(duration: string | undefined): Duration {
    return duration ? dayjs.duration(duration) : dayjs.duration(0, 'minutes')
}

export function getRoomDuration(surgery: MinimalScheduledSurgery): Duration {
    return getDayjsDuration(surgery.plannedDuration)
}

export function getKnifeTime(surgery: MinimalScheduledSurgery): Duration {
    return getDayjsDuration(surgery.plannedProcedureDuration)
}
