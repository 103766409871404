import { useState } from 'react'

import { Button, Show } from '~/components'
import env from '~/env'
import { useUnScheduledSuggestions } from '~/hooks/booking/useUnScheduledSuggestions'
import { HelpOutlined } from '~/icons'
import { OccupancyData, Practitioner, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'

import { BookingDialog } from './BookingDialog/BookingDialog'
import { EmptyItem } from './EmptyItem'
import { LoadingCard } from './LoadingCard'
import { SuggestionsInfoCard } from './SuggestionsInfoCard'
import { SurgeryCard } from './SurgeryCard'
import { SurgerySearchDialog } from './SurgerySearchDialog'

const MAX_LIST_LENGTH = 5

type Props = {
    occupancyData: OccupancyData | null
    practitioners: Practitioner[]
}

export const BookingWaitingList = ({ practitioners, occupancyData }: Props) => {
    const [showBookingDialog, setShowBookingDialog] = useState<boolean>(false)
    const [selectedSurgery, setSelectedurgery] = useState<UnScheduledSurgery>()
    const settings = useStore(state => state.user.settings)
    const setHideSuggestionsInfo = useStore(state => state.user.actions.setHideSuggestionsInfo)
    const { unScheduledSuggestions, suggestionCount, isLoading } = useUnScheduledSuggestions({
        scheduledPractitioners: practitioners,
        occupancyData,
    })
    const [showSuggestionsCard, setShowSuggestionsCard] = useState(!settings.hideSuggestionsInfo)
    const [showSearchModal, setShowSearchModal] = useState<boolean>(false)

    const handleHideAlwaysButtonClick = () => {
        setHideSuggestionsInfo(true)
        setShowSuggestionsCard(false)
    }
    const onCloseDialog = () => {
        setShowBookingDialog(false)
        setSelectedurgery(undefined)
    }

    const hasSuggestions = suggestionCount > 0

    const displayedSuggestions = suggestionCount > MAX_LIST_LENGTH ? unScheduledSuggestions.slice(0, MAX_LIST_LENGTH) : unScheduledSuggestions

    const listTitle = `${suggestionCount > MAX_LIST_LENGTH ? `Topp ${MAX_LIST_LENGTH} av ${suggestionCount}` : `${suggestionCount}`} mulige pasienter`

    return (
        <>
            <div className="flex items-center justify-between">
                <p className="flex items-center gap-1 text-base">
                    {listTitle}
                    <Show condition={hasSuggestions}>
                        <Button size="icon" color="custom" onClick={() => setShowSuggestionsCard(prev => !prev)}>
                            <HelpOutlined />
                        </Button>
                    </Show>
                </p>
            </div>
            {showSuggestionsCard && hasSuggestions && (
                <SuggestionsInfoCard
                    showButton={!settings.hideSuggestionsInfo}
                    onHideButtonClick={handleHideAlwaysButtonClick}
                    onClose={() => setShowSuggestionsCard(false)}
                />
            )}
            {env.VITE_FF_OPERATIONAL_PLANNER_BOOKING_SEARCH_SURGERIES && (
                <div className="flex gap-4">
                    <Button className="border border-gray-200 bg-gray-100" color="custom" onClick={() => setShowSearchModal(true)}>
                        Søk etter pasient
                    </Button>
                </div>
            )}
            <Show condition={!isLoading} fallback={<LoadingCard />}>
                {hasSuggestions ? (
                    displayedSuggestions.map(surgery => (
                        <SurgeryCard
                            surgery={{ type: 'unscheduled', value: surgery }}
                            key={surgery.bookingId}
                            onClick={surg => {
                                setSelectedurgery(surg)
                                setShowBookingDialog(true)
                            }}
                        />
                    ))
                ) : (
                    <EmptyItem>
                        <p>
                            Vi fant ingen pasienter som passer disse operasjonstypene på ventelisten
                            {practitioners.length > 0 && ' til '}
                            {practitioners.length > 0 && <span className="font-semibold">{practitioners.map(prac => prac.short_name).join(', ')}</span>} eller
                            med uspesifisert kirurg.
                        </p>
                    </EmptyItem>
                )}
                <div data-test="booking-drawer-waiting-list-loaded" className="hidden" />
            </Show>
            {selectedSurgery && occupancyData && (
                <BookingDialog isOpen={showBookingDialog} onCloseDialog={onCloseDialog} waitingListItem={selectedSurgery} occupancyData={occupancyData} />
            )}
            <SurgerySearchDialog isOpen={showSearchModal} onClose={() => setShowSearchModal(false)} />
        </>
    )
}
