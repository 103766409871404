import { FilterDropdown, LocationFilter, PractitionerFilter, Show, StatusFilter } from '~/components'
import { selectSurgeonSchedulerFilters } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'

type Props = {
    isPractitionerView: boolean
}

export const Filters = ({ isPractitionerView }: Props) => {
    const { emptyFilters, removeSingleFilter } = useStore(state => state.appFilters.actions)
    const filters = useStore(selectSurgeonSchedulerFilters)

    return (
        <FilterDropdown clearFilters={emptyFilters} onDisplayItemRemove={removeSingleFilter} displayItems={filters}>
            <Show
                condition={isPractitionerView}
                fallback={
                    <>
                        <PractitionerFilter />
                        <LocationFilter />
                    </>
                }
            >
                <PractitionerFilter />
                <StatusFilter />
            </Show>
        </FilterDropdown>
    )
}
