import { type ReactNode } from 'react'

function PageLayout({ content, header, sidebar }: { content: ReactNode; header: ReactNode; sidebar: ReactNode }) {
    return (
        <div className="flex h-full w-full flex-col">
            <div className="shrink p-2">{header}</div>
            <div className="flex grow overflow-hidden p-2 pt-0">
                <div className="h-full grow overflow-hidden">{content}</div>
                <div className="h-full">{sidebar}</div>
            </div>
        </div>
    )
}

export default PageLayout
