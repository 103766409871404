import clsx from 'clsx'
import { Dayjs } from 'dayjs'
import { AnimatePresence } from 'framer-motion'

import { DataGrid, HolidayTag, Show } from '~/components'
import {
    importPractitionerScheduleLocations,
    importPractitionerSchedules,
    importPractitionerScheduleStatuses,
    importPractitionerServicePeriods,
} from '~/store/di-entity.api'
import {
    Practitioner,
    PractitionerSchedule,
    selectGetAllOnCallPractitionerSchedules,
    selectGetPractitioners,
    selectGetPractitionerSchedules,
    selectGetPractitionerScheduleStatuses,
    selectMonthIdDates,
    selectTimeArgs,
} from '~/store/selectors'
import { selectPractitionerValues, Status } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { day, format, getToday, isHoliday, isSameDate } from '~/utils/extendedDayjs'
import { isNotNullish } from '~/utils/guards'
import { getHolidayStyles } from '~/utils/utils'

import { OnCallCell } from '../shared/OnCallCell'
import { getFridayStyles } from '../shared/utils'
import { CellScheduleLocations } from './Cell/CellScheduleLocations'
import { CellScheduleStatuses } from './Cell/CellScheduleStatuses'
import { Popover } from './KeyboardInteraction/Popover'
import { PractitionerScheduleModal } from './Modal/PractitionerScheduleModal'

const isActivePractitioner = (filteredPractitioner: Practitioner[], schedule?: PractitionerSchedule) => {
    return filteredPractitioner.map(practitioner => practitioner.id).includes(Number(schedule?.practitioner?.id))
}

export const PractitionerTable = () => {
    const timeArgs = useStore(selectTimeArgs)

    const { isLoading } = useImportEntities(
        () => [
            importPractitionerScheduleStatuses({
                'practitioner_schedule.start_time:gte': timeArgs['start_time:gte'],
                'practitioner_schedule.start_time:lte': timeArgs['start_time:lte'],
            }),
            importPractitionerScheduleLocations({
                'practitioner_schedule.start_time:gte': timeArgs['start_time:gte'],
                'practitioner_schedule.start_time:lte': timeArgs['start_time:lte'],
            }),
            importPractitionerServicePeriods({}),
            importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true, exclude_locations: true, exclude_statuses: true }),
        ],
        [timeArgs]
    )

    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const monthIdDates = useStore(selectMonthIdDates)
    const filteredPractitioners = useStore(selectPractitionerValues)
    const getPractitioners = useStore(selectGetPractitioners)
    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getPractitionerScheduleStatuses = useStore(selectGetPractitionerScheduleStatuses)
    const onCallPractitionerSchedules = useStore(selectGetAllOnCallPractitionerSchedules)

    const practitionersByDepartment = getPractitioners.byDepartmentKey(departmentKey)
    const activePractitioners = practitionersByDepartment.filter(
        practitioner => filteredPractitioners.length === 0 || filteredPractitioners.includes(practitioner.short_name)
    )

    function getCommentStyle(practitioner: Practitioner, date: Dayjs) {
        if (isHoliday(date)) return false

        const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id)
        if (!schedule) return false

        const statuses = getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id).map(status => status.status_code)
        if (!statuses.includes('OTHER')) return false

        const condition = isNotNullish(schedule.comment) && schedule.comment !== ''

        return condition ? 'bg-emerald-300/10  small-triangle-bookmark' : ''
    }

    function getMatchingPractitioners(statuses: Status[], date: Dayjs) {
        const practitionerSchedulesByDate = getPractitionerSchedules.byDate(date)
        const practitionerScheduleStatuses = practitionerSchedulesByDate.flatMap(schedule =>
            getPractitionerScheduleStatuses.byPractitionerScheduleId(schedule.id)
        )

        const matching = practitionerScheduleStatuses
            .filter(({ schedule }) => isActivePractitioner(activePractitioners, schedule))
            .filter(status => statuses.includes(status.status_code))

        const names = matching.map(({ schedule }) => schedule?.practitioner?.short_name).join(', ')
        return { total: matching.length, names: names }
    }

    const weekInfoLabel = `${monthIdDates.at(0)?.date.isoWeek()}-${monthIdDates.at(-1)?.date.isoWeek()}`

    return (
        <DataGrid
            data-test="practitioner-table"
            isLoading={isLoading}
            selection="multiple"
            header={{
                cells: monthIdDates,
                rowHeader: () => <div className="w-full truncate">{weekInfoLabel}</div>,
                cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date), { 'calendar-today': isSameDate(date, getToday()) }),
                cellRender: ({ date }) => {
                    const dateIsHoliday = isHoliday(date)
                    return (
                        <div className="flex h-full flex-col gap-1">
                            <span className="hidden first-letter:capitalize xl:block">{format(date, 'dd D')}</span>
                            <span className="first-letter:capitalize xl:hidden">
                                {format(date, 'dd').at(0)} {format(date, 'D')}
                            </span>
                            <Show condition={dateIsHoliday}>
                                <HolidayTag />
                            </Show>
                        </div>
                    )
                },
                extraHeaders: [
                    {
                        key: 'on-call',
                        rowHeader: () => <p className="truncate p-1">Ox</p>,
                        cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                        cellRender: ({ date }) => <OnCallCell date={date} onCallSchedules={onCallPractitionerSchedules.byDate(date)} />,
                    },
                ],
            }}
            body={{
                rows: activePractitioners,
                rowRender: practitioner => practitioner.short_name,
                cellClassName: (practitioner, { date }) => clsx(getCommentStyle(practitioner, date), getFridayStyles(date), getHolidayStyles(date)),
                cellRender: (practitioner, { date }) => {
                    if (isHoliday(date)) return null

                    const schedule = getPractitionerSchedules.byDateAndPractitionerId(date, practitioner.id)
                    const gap = Number(schedule?.locationAssignments.length) > 0 && Number(schedule?.statuses.length) > 0 ? ' gap-y-1' : ''

                    return (
                        <div className={`flex flex-col ${gap}`}>
                            <CellScheduleStatuses schedule={schedule} />
                            <CellScheduleLocations schedule={schedule} />
                        </div>
                    )
                },
            }}
            footers={[
                {
                    key: 'operation',
                    rowHeader: () => <span className="text-xs">Operasjon</span>,
                    cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithSurgeryOrOnCallStatus = getMatchingPractitioners(['SURGERY', 'ON-CALL'], date)
                        return (
                            <Show condition={practitionersWithSurgeryOrOnCallStatus.total > 0}>
                                <span className="text-xs" data-tooltip={practitionersWithSurgeryOrOnCallStatus.names}>
                                    {practitionersWithSurgeryOrOnCallStatus.total}
                                </span>
                            </Show>
                        )
                    },
                },
                {
                    key: 'day-surgery',
                    rowHeader: () => <span className="text-xs">Dagkirurgi</span>,
                    cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithDaySurgeryStatus = getMatchingPractitioners(['DAY_SURGERY'], date)
                        return (
                            <Show condition={practitionersWithDaySurgeryStatus.total > 0}>
                                <span className="text-xs" data-tooltip={practitionersWithDaySurgeryStatus.names}>
                                    {practitionersWithDaySurgeryStatus.total}
                                </span>
                            </Show>
                        )
                    },
                },
                {
                    key: 'poliklinikk',
                    rowHeader: () => <span className="text-xs">Poliklinikk</span>,
                    cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithPolyclinicStatus = getMatchingPractitioners(['POLICLINIC'], date)
                        return (
                            <Show condition={practitionersWithPolyclinicStatus.total > 0}>
                                <span className="text-xs" data-tooltip={practitionersWithPolyclinicStatus.names}>
                                    {practitionersWithPolyclinicStatus.total}
                                </span>
                            </Show>
                        )
                    },
                },
                {
                    key: 'evening-poliklinikk',
                    rowHeader: () => <span className="text-xs">Kveldspoliklinikk</span>,
                    cellClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        if (isHoliday(date)) return null

                        const practitionersWithEveningPolyclinicStatus = getMatchingPractitioners(['EVENING_POLYCLINIC', 'EVENING_POLICLINIC'], date)
                        return (
                            <Show condition={practitionersWithEveningPolyclinicStatus.total > 0}>
                                <span className="text-xs" data-tooltip={practitionersWithEveningPolyclinicStatus.names}>
                                    {practitionersWithEveningPolyclinicStatus.total}
                                </span>
                            </Show>
                        )
                    },
                },
            ]}
            modal={cells => (
                <PractitionerScheduleModal
                    selectedCells={cells.map(({ rowId, colId }) => ({
                        date: day(colId),
                        practitionerId: Number(rowId),
                    }))}
                />
            )}
            keyboardInteractionComponent={(isModalOpen, setIsModalOpen, cells, clearSelection) => (
                <AnimatePresence>
                    <Show condition={cells.length > 0 && !isModalOpen}>
                        <Popover
                            selectedCells={cells.map(({ rowId, colId }) => ({
                                date: day(colId),
                                practitionerId: Number(rowId),
                            }))}
                            clearSelection={clearSelection}
                            setIsOpen={setIsModalOpen}
                        />
                    </Show>
                </AnimatePresence>
            )}
        />
    )
}
