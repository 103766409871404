import { Dayjs } from 'dayjs'
import { useMemo } from 'react'

import { EmptyItem } from '~/app/pages/OperationalPlanner/BookingDrawer/EmptyItem'
import { OccupancyData, selectGetOccupancies, UnScheduledSurgery } from '~/store/selectors'
import { useStore } from '~/store/store'
import { getSurgeons } from '~/utils/dips'

import { OperatingTheaterList } from './OperatingTheaterList'

type Props = {
    selectedDay?: Dayjs
    showAllPractitioners: boolean
    surgery: UnScheduledSurgery
    selectedMonth: Dayjs
    isLoading: boolean
    onClick: (selectedOccupancy: OccupancyData) => void
}

export const AvailableLocations = ({ selectedDay, showAllPractitioners, surgery, selectedMonth, isLoading, onClick }: Props) => {
    const surgeryPractitioner = surgery ? getSurgeons(surgery.surgeryResources) : []

    const surgeryTypeId = surgery?.bookingId ? (surgery.surgeryType?.id ?? 0) : 0

    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const occupancies = useStore(selectGetOccupancies)

    const occupancyData = useMemo(() => {
        return isLoading ? {} : occupancies.byMonth(selectedMonth.startOf('month'), departmentKey, true, surgeryTypeId)
    }, [isLoading, occupancies, selectedMonth, departmentKey, surgeryTypeId])

    const occupancyForSelectedDate = useMemo(() => selectedDay && occupancyData[selectedDay.format('YYYY-MM-DD')], [selectedDay, occupancyData])
    return (
        <div className="flex flex-col gap-4">
            {occupancyForSelectedDate && selectedDay && (
                <OperatingTheaterList
                    occupancies={occupancyForSelectedDate}
                    date={selectedDay}
                    onClick={onClick}
                    surgeryPractitioner={surgeryPractitioner}
                    showAllPractitioners={showAllPractitioners}
                />
            )}
            {!selectedDay && (
                <EmptyItem>
                    <p>Ingen dato valgt</p>
                </EmptyItem>
            )}
        </div>
    )
}
