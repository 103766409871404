import { memo, ReactNode } from 'react'

import { Id } from '~/components/DataGrid/utils'

export type CellRender<Row extends { id: Id }, Col extends { id: Id }> = (row: Row, col: Col) => ReactNode

type Props<Row extends { id: Id }, Col extends { id: Id }> = {
    row: Row
    col: Col
    cellRender: CellRender<Row, Col>
}

function TableCellComponent<Row extends { id: Id }, Col extends { id: Id }>({ cellRender, col, row }: Props<Row, Col>) {
    return cellRender(row, col)
}

export const TableCell = memo(TableCellComponent) as typeof TableCellComponent
