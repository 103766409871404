import clsx from 'clsx'
import { Fragment, memo, ReactNode, RefObject } from 'react'

import { Id } from '../utils'

type HeaderProps<Cell extends { id: Id }> = {
    gridTemplateColumns: string
    headerRef: RefObject<HTMLDivElement>
    rowHeader: () => ReactNode
    cells: Cell[]
    cellRender: (header: Cell) => ReactNode
    cellClassName?: (header: Cell) => string

    extraHeaders?: {
        key: string
        rowHeader: () => ReactNode
        cellRender: (header: Cell) => ReactNode
        cellClassName?: (header: Cell) => string
    }[]
}

export type ExternalHeaderProps<Cell extends { id: Id }> = Omit<HeaderProps<Cell>, 'gridTemplateColumns' | 'headerRef'>

function HeaderComponent<Cell extends { id: Id }>({
    rowHeader,
    cells,
    cellClassName,
    cellRender,
    gridTemplateColumns,
    headerRef,
    extraHeaders,
}: HeaderProps<Cell>) {
    return (
        <div
            ref={headerRef}
            className="sticky top-0 z-20 grid whitespace-nowrap"
            style={{
                gridTemplateColumns,
                gridColumn: `1 / span ${cells.length + 1}`,
            }}
        >
            <div className="sticky left-0 border-b border-r border-[#8391c3] bg-white p-1">{rowHeader()}</div>
            {cells.map(cell => (
                <div key={cell.id} className={clsx('border-b border-r border-b-[#8391c3] bg-white p-1 text-center', cellClassName?.(cell))}>
                    {cellRender(cell)}
                </div>
            ))}

            {extraHeaders?.map(({ key, rowHeader, cellRender, cellClassName }) => (
                <Fragment key={key}>
                    <div className="sticky left-0 border-b border-r border-[#8391c3] bg-white">{rowHeader()}</div>
                    {cells.map(cell => (
                        <div key={cell.id} className={clsx('border-b border-r border-b-[#8391c3] bg-white text-center', cellClassName?.(cell))}>
                            {cellRender(cell)}
                        </div>
                    ))}
                </Fragment>
            ))}
        </div>
    )
}

export const Header = memo(HeaderComponent) as typeof HeaderComponent
