import { Show } from '~/components'
import { InfoCircleOutlined } from '~/icons'
import { useStore } from '~/store/store'

import { columns } from '../shared/columns'
import { shouldShowASAColumn, waitingTimeTooltip } from '../shared/utils'
import { WaitingListTags } from '../shared/WaitingListTags'
import { Comment } from './Comment/Comment'
import { DrawerElement, DrawerElementWithTags } from './DrawerElement'

function formatEmptyAsDash(strings: string | string[]): string | string[] {
    if (strings.length === 0) return '—'

    return strings
}

export const Contents = () => {
    const drawerProps = useStore(state => state.waitingList.drawerProps)
    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const activeView = useStore(state => state.app.activeViews.WAITING_LIST)

    if (drawerProps === null) return <div />
    const { item } = drawerProps

    return (
        <div className="flex flex-col gap-5">
            <Comment item={item} />

            <DrawerElement
                title={
                    <div className="flex items-center gap-1">
                        {columns.WaitingTime.label}
                        <InfoCircleOutlined className="h-5 w-5" data-tooltip={waitingTimeTooltip} />
                    </div>
                }
            >
                {item.WaitingTime}
            </DrawerElement>

            <Show condition={shouldShowASAColumn(departmentKey)}>
                <DrawerElement title={columns.ASA.label}>
                    <WaitingListTags tags={item.ASA} tagColor="ASA" fallback="—" />
                </DrawerElement>
            </Show>

            <DrawerElement title={columns.BMI.label}>{formatEmptyAsDash(item.BMI)}</DrawerElement>

            <Show condition={activeView === '/waiting-list/unscheduled' || (activeView === '/waiting-list/all' && item.BookedStatus !== 'Scheduled')}>
                <DrawerElement title="Kort varsel">{formatEmptyAsDash(item.Remarks.includes('shortNotice') ? 'Kort varsel' : '')}</DrawerElement>
            </Show>

            <DrawerElement title={columns.ContactReason.label}>{formatEmptyAsDash(item.ContactReason)}</DrawerElement>

            <DrawerElementWithTags title={columns.SurgeryType.label} tags={item.SurgeryType} />

            <DrawerElementWithTags title={columns.Surgeons.label} tags={item.Surgeons} />

            <DrawerElementWithTags title={columns.FirstAssistants.label} tags={item.FirstAssistants} />

            <DrawerElement title={columns.KnifeTime.label}>{formatEmptyAsDash(item.KnifeTime)}</DrawerElement>

            <DrawerElement title={item.BookedStatus === 'Waiting' ? columns.TentativeDate.label : 'Operasjonsdato'}>
                <div className="first-letter:capitalize">{formatEmptyAsDash(item.TentativeDate)}</div>
            </DrawerElement>

            <DrawerElement title={columns.Day.label}>{formatEmptyAsDash(item.Day)}</DrawerElement>
        </div>
    )
}
