import dayjs from 'dayjs'
import { NorwegianId } from 'norwegian-national-id-validator'

import { DipsSchemas } from '~/clients/dips-client'
import { day, getToday } from '~/utils/extendedDayjs'
import { isNotNullish, isNullish } from '~/utils/guards'

type Patient = DipsSchemas['Patient']

export function getBirthDate(patient: Patient | undefined): dayjs.Dayjs | undefined {
    if (isNullish(patient)) {
        return undefined
    }
    if (isNotNullish(patient.birthDate)) {
        return day(patient.birthDate)
    }
    if (isNotNullish(patient.nationalId)) {
        const fromNorwegianId = NorwegianId(patient?.nationalId).birthDate()
        return isNotNullish(fromNorwegianId) ? day(fromNorwegianId) : undefined
    }
    return undefined
}

export function getAge(patient: Patient | undefined): number | undefined {
    const birthDate = getBirthDate(patient)
    if (isNotNullish(birthDate)) {
        return getToday().diff(birthDate, 'year')
    }
    return undefined
}

export const formatPersonalId = (personalId: string) => {
    return `${personalId.slice(0, 6)} ${personalId.slice(6)}`
}

export function formatPersonalIdWithLastName(patient?: { nationalId?: string | null; lastName?: string | null }) {
    const formattedElement1 = `${formatPersonalId(patient?.nationalId ?? '')}`
    const formattedElement2 = patient?.lastName ?? ''

    if (formattedElement1 === '' && formattedElement2 === '') {
        return '??'
    }

    return [formattedElement1, formattedElement2].filter(Boolean).join(', ')
}
