import clsx from 'clsx'
import { Dayjs } from 'dayjs'

import { HolidayTag, Show, Table } from '~/components'
import { importLocationSchedules, importPractitionerSchedules } from '~/store/di-entity.api'
import {
    Location,
    PractitionerSchedule,
    selectActiveFilteredLocations,
    selectGetAllOnCallPractitionerSchedules,
    selectGetLocationSchedules,
    selectGetPractitionerSchedules,
    selectMonthIdDates,
    selectTimeArgs,
} from '~/store/selectors'
import { DepartmentKey, selectPractitionerValues } from '~/store/slices/filterSlice'
import { useStore } from '~/store/store'
import { useImportEntities } from '~/store/useImportEntities'
import { format, getToday, isHoliday, isSameDate } from '~/utils/extendedDayjs'
import { getHolidayStyles } from '~/utils/utils'

import { ButtonTag } from '../shared/ButtonTag'
import { OnCallCell } from '../shared/OnCallCell'
import { comparePractitionersOrder, getFridayStyles, keyFromTableCells } from '../shared/utils'
import { LocationScheduleModal } from './Modal/LocationScheduleModal'
import { operatingStatuses, operatingStatusesForLocationAssignment, selectedCellsToLocationDateCells } from './utils'

const isPractitionerInDepartment = (schedule: PractitionerSchedule, departmentKey: DepartmentKey) => {
    return departmentKey === 'all' || schedule.practitioner?.departments.some(department => department.id === departmentKey)
}

const sortSchedulesByPractitioner = (schedules: PractitionerSchedule[], departmentKey: DepartmentKey) => {
    return [...schedules].sort((a, b) => comparePractitionersOrder(departmentKey, a.practitioner, b.practitioner))
}

export const LocationTable = () => {
    const timeArgs = useStore(selectTimeArgs)

    useImportEntities(
        () => [
            importPractitionerSchedules({ ...timeArgs, exclude_practitioner: true }), // include practitioner's status + practitioner's locations
            importLocationSchedules({ ...timeArgs, exclude_location: true }),
        ],
        [timeArgs]
    )

    const departmentKey = useStore(state => state.appFilters.departmentKey)
    const monthIdDates = useStore(selectMonthIdDates)
    const filteredPractitionerValues = useStore(selectPractitionerValues)
    const locationRows = useStore(selectActiveFilteredLocations)
    const allOnCallPractitionerSchedules = useStore(selectGetAllOnCallPractitionerSchedules)

    const getPractitionerSchedules = useStore(selectGetPractitionerSchedules)
    const getLocationSchedules = useStore(selectGetLocationSchedules)

    function getCommentStyle(location: Location, date: Dayjs) {
        const locationSchedule = getLocationSchedules.byDateAndLocation(date, location)
        const hasFilledComment = locationSchedule?.comment && locationSchedule.comment.trim().length > 0

        return hasFilledComment ? 'bg-emerald-300/10  small-triangle-bookmark' : ''
    }

    const weekInfoLabel = `Uke ${monthIdDates.at(0)?.date.isoWeek()}-${monthIdDates.at(-1)?.date.isoWeek()}`

    return (
        <Table
            isLoading={false}
            data-test="location-table"
            header={{
                label: weekInfoLabel,
                row: monthIdDates,
                rowClassName: ({ date }) => clsx('p-2', getFridayStyles(date), getHolidayStyles(date), { 'calendar-today': isSameDate(date, getToday()) }),
                rowRender: ({ date }) => {
                    const dateIsHoliday = isHoliday(date)
                    return (
                        <div className="flex h-[100%] flex-col gap-1">
                            <div className="whitespace-nowrap capitalize">{format(date, 'DD.MM')}</div>
                            <Show condition={dateIsHoliday}>
                                <HolidayTag />
                            </Show>
                        </div>
                    )
                },
            }}
            extraHeaders={[
                {
                    label: 'On-call',
                    row: monthIdDates,
                    rowClassName: ({ date }) => clsx('!p-0', getFridayStyles(date), getHolidayStyles(date)),
                    rowRender: ({ date }) => <OnCallCell date={date} onCallSchedules={allOnCallPractitionerSchedules.byDate(date)} />,
                },
            ]}
            body={{
                rows: locationRows,
                rowRender: x => x.room_code,
                cellClassName: (location, { date }) => clsx(getCommentStyle(location, date), getFridayStyles(date), getHolidayStyles(date)),
                cellRender: (location, { date }) => {
                    if (isHoliday(date)) return null

                    const schedules = getPractitionerSchedules
                        .byDateAndLocation(date, location)
                        .filter(schedule => isPractitionerInDepartment(schedule, departmentKey))

                    const sortedSchedules = sortSchedulesByPractitioner(schedules, departmentKey)

                    return (
                        <div className="flex flex-col items-center gap-2">
                            {sortedSchedules.map((schedule, index) => {
                                const shortName = schedule.practitioner?.short_name
                                const isFiltered = filteredPractitionerValues.length > 0 && shortName && !filteredPractitionerValues.includes(shortName)
                                const isOperating = schedule.statuses.some(status => operatingStatuses.includes(status.status_code))

                                return (
                                    <div key={index}>
                                        <ButtonTag
                                            className={clsx({ 'opacity-30': isFiltered })}
                                            colorType={!isOperating ? 'red' : schedule.locations.length > 1 ? 'room' : 'room'}
                                            fillType="all"
                                            data-tooltip={!isOperating ? 'Ikke tildelt operasjon' : ''}
                                        >
                                            {shortName}
                                        </ButtonTag>
                                    </div>
                                )
                            })}
                        </div>
                    )
                },
            }}
            footers={[
                {
                    header: 'Fordelte kirurger',
                    footerClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        const practitionersDistributed = getPractitionerSchedules
                            .byDate(date)
                            .filter(schedule => isPractitionerInDepartment(schedule, departmentKey))
                            .filter(schedule => schedule.statuses.some(status => operatingStatuses.includes(status.status_code)))
                            .filter(schedule => schedule.locations.length > 0)

                        const summary = practitionersDistributed.map(schedule => schedule.practitioner?.short_name).join(', ')

                        return (
                            <Show condition={practitionersDistributed.length > 0}>
                                <span data-tooltip={summary}>{practitionersDistributed.length}</span>
                            </Show>
                        )
                    },
                },
                {
                    header: 'Kirurger uten stue',
                    footerClassName: ({ date }) => clsx(getFridayStyles(date), getHolidayStyles(date)),
                    cellRender: ({ date }) => {
                        const schedulesNotDistributed = getPractitionerSchedules
                            .byDate(date)
                            .filter(schedule => isPractitionerInDepartment(schedule, departmentKey))
                            .filter(schedule => schedule.statuses.some(status => operatingStatusesForLocationAssignment.includes(status.status_code)))
                            .filter(schedule => schedule.locations.length === 0)

                        const summary = schedulesNotDistributed.map(schedule => schedule.practitioner?.short_name).join(', ')

                        return (
                            <Show condition={schedulesNotDistributed.length > 0}>
                                <span data-tooltip={summary}>{schedulesNotDistributed.length}</span>
                            </Show>
                        )
                    },
                },
            ]}
            modal={selectedCells => (
                <LocationScheduleModal key={keyFromTableCells(selectedCells)} selectedCells={selectedCellsToLocationDateCells(selectedCells)} />
            )}
        />
    )
}
